@font-face {
  font-family: 'Almarai-Regular';
  src: url('/src/assets/fonts/Almarai-Regular.ttf');
}

@font-face {
  font-family: 'Almarai-Bold';
  src: url('/src/assets/fonts/Almarai-Bold.ttf');
}

@font-face {
  font-family: 'Almarai-ExtraBold';
  src: url('/src/assets/fonts/Almarai-ExtraBold.ttf');
}

@font-face {
  font-family: 'Eina03-Regular';
  src: url('/src/assets/fonts/Eina03-Regular.ttf');
}

@font-face {
  font-family: 'Eina03-SemiBold';
  src: url('/src/assets/fonts/Eina03-SemiBold.ttf');
}

@font-face {
  font-family: 'Eina03-Bold';
  src: url('/src/assets/fonts/Eina03-Bold.ttf');
}

@font-face {
  font-family: 'Eina04-Bold';
  src: url('/src/assets/fonts/Eina04-Bold.ttf');
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.Toastify__toast{
  font-size: 14px;
  font-family: 'Eina03-Regular';
}
.ar .Toastify__toast{
  font-family: 'Almarai-Regular';
}