.checkout-page {
    display: flex;
    margin-bottom: 100px;
    justify-content: space-between;

    @media screen and (max-width: 1070px) {
        flex-direction: column;
        gap: 10px;
    }
}

.shipping-form-wrapper {
    flex: 1;
}

.shipping-form, .cart-breakdown {
    flex: 1;
    height: 75vh;
    padding: 25px;
    overflow-y: scroll;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    border-radius: 10px
}
.shipping-form{
    height: unset;
}

.cart-breakdown {
    background-color: rgb(250, 250, 250);
}

.shipping-form::-webkit-scrollbar, .cart-breakdown::-webkit-scrollbar {
    display: none;
}

.checkout-form, .coupon-section, .login-form {
    display: flex;
    flex-direction: column;
    gap: 14px;
}

.login-form {
    position: relative;
    text-align: center;
}

.login-close-icon {
    cursor: pointer;
    position: absolute;
    right: 0;
    left: unset;

    .ar & {
        left: 0;
        right: unset;
    }
}

.login-close-icon:hover {
    opacity: 0.7;
}

.checkout-input-divider {
    display: flex;
    gap: 10px;

    @media screen and (max-width: 550px) {
        flex-direction: column;
    }
}

.checkout-input, .checkout-phone-input .PhoneInputInput {
    padding: 15px;
    border: 1px solid hsl(0, 0%, 70%);
    border-radius: 4px;
    flex: 1;
}

.checkout-input::placeholder,
.checkout-phone-input .PhoneInputInput::placeholder
{
    color: hsl(0, 0%, 50%) !important;
    font-size: 15px;
    font-family: Eina03-Regular;
}

.ar .checkout-input::placeholder,
.ar .checkout-phone-input .PhoneInputInput::placeholder {
    font-size: 14px;
    font-family: Almarai-Regular;
}

.checkout-input:focus-visible, .checkout-phone-input .PhoneInputInput:focus-visible {
    outline: #ff2d47 auto 1px;
}

.country-select__control {
    box-shadow: 0 0 0 1px transparent !important;
    border: 1px solid hsl(0, 0%, 70%) !important;
    padding: 5px;
}

.country-select__control:focus-within {
    box-shadow: 0 0 0 1px #ff2d47 !important;
    outline: #ff2d47 auto 1px !important;
}
.payment-gateway-option{
    display: flex;
    gap: 10px;
    align-items: baseline;
    margin-top: 12px;

}
.payment-gateway-option label{
    flex: 1;
}

.checkout-payment-description-span {
    color: rgba(0, 0, 0, 0.56);

}
.checkout-payment-wrapper{
    margin-top: 12px;
}

.checkout-payment-method-wrapper {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    box-shadow: 0 0 0 1px hsl(0, 0%, 70%);
    border-radius: 12px;
}

.checkout-payment-method-wrapper:hover {
    background-color: rgb(242, 242, 242);
}

.checkout-payment-method-span {
    font-size: 15px;
}

.checkout-cart-item {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
}

.checkout-cart-item-img-wrapper {
    width: 50px;
    height: 50px;
    position: relative;
    border-radius: 5px;
    box-shadow: 0 0 0 1px hsl(0, 0%, 70%);
}

.checkout-cart-item-quantity {
    position: absolute;
    right: 0;
    transform: translate(50%, -50%);
    background-color: rgba(0, 0, 0, 0.56);
    color: white;
    border-radius: 100%;
    font-size: 12px;
    line-height: 18px;
    padding: 2px 7px;
}

.checkout-cart-item-title, .checkout-cart-item, .checkout-subtotal, .checkout-shipping, .coupon-summary-code {
    font-size: 14px;
    font-family: Eina03-SemiBold;
    .ar & {
      font-family: Almarai-Bold;
    }
}
.checkout-subtotal {
    .ar & {
        direction: ltr;
    }
}
.checkout-cart-item-price-s{
    font-size: 12px;
    font-family: Eina03-SemiBold;
    .ar & {
      font-family: Almarai-Bold;
    }
}
.coupon-summary-code{
    overflow: hidden;
    @media screen and (max-width: 550px) {
        font-size: 12px;
    }
}

.checkout-total {
    font-size: 19px;
    font-family: Eina03-Bold;
    .ar & {
      font-family: Almarai-ExtraBold;
    }
}

.checkout-cart-item-breakdown {
    margin: 0 10px;
    flex: 1;
    flex-direction: column;
    display: flex;
}

.checkout-coupon-form {
    display: flex;
    justify-content: space-between;
    gap: 10px;
}

.checkout-coupon-button {
    cursor: pointer;
    padding: 20px;
    background-color: #ff2d47;
    color: white;
    border: none;
    border-radius: 5px;
    font-family: Eina03-Regular;

    .ar & {
        font-family: Almarai-Regular;
    }
}

.checkout-coupon-button:hover {
    opacity: 0.8;
}
.checkout-coupon-button:disabled {
    background-color: #e8e8e8;
    cursor: not-allowed;
}

.checkout-summary-wrapper {
    display: flex;
    justify-content: space-between;
}
.coupon-summary-wrapper {
    display: flex;
    gap: 5px;
}
.coupon-summary {
    display: flex;
    justify-content: space-between;
    max-width: 40%;
    align-items: center;
    background-color: #e8e8e8;
    padding: 5px 9px;
    border-radius: 5px;
    gap: 5px;
}
.coupon-summary-code.code-clear{
    cursor: pointer;
    font-size: 14px;
    overflow: unset;
}
.checkout-coupon-error{
    color: #ff2d47;
    font-size: 14px;
    margin: 0;
}
.form-error{
    color:#ff2d47;
    margin:0;
    font-size: 14px;
}